/* Links */
a,
a:focus,
a:hover {
	// color: #333;
}

/*
 * Header
 */
.masthead {
  margin-bottom: 2rem;
}

.masthead-brand {
  font-family: "Niramit", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
}

.nav .nav-link {
	padding: .25rem 0;
	font-family: "Niramit", sans-serif;
	font-weight: 700;
	color: rgba(0, 0, 0, .5);
	background-color: transparent;
	border-bottom: .25rem solid transparent;
}

.nav .nav-link:hover,
.nav .nav-link:focus {
  border-bottom-color: rgba(0, 0, 0, .25);
}

.nav .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav .active {
	color: #333;
	border-bottom-color: #333;
}

@media (min-width: 48em) {
  .masthead-brand {
    float: left;
  }
  .nav-masthead {
    float: right;
  }
}

/*
 * Content
 */

.recipe-instructions {
	list-style-type: none;
	counter-reset: listNumbering;
	padding-left: 0;
}

.recipe-instructions>li {
	counter-increment: listNumbering;
	vertical-align: text-top;
	margin-bottom: 1rem;
}

.recipe-instructions>li::before {
	content: counter(listNumbering);
	font-size: 1.5em;
	font-family: 'Fredoka One', cursive;
	color: maroon;
	padding-right: 1rem;
}

.recipe-title {
	font-family: 'Fredoka One', cursive;
	color: maroon;
}

.recipe-header {
	font-family: 'Fredoka One', cursive;
	color: maroon;
}

.recipe-title>a {
	color: maroon;
}

.recipe-primary {
	font-family: 'Crete Round', serif;
	color: #333;
}

.recipe-secondary {
	font-family: 'Niramit', sans-serif;
	font-weight: 700;
	color: #333;
}

.instruction {
	font-size: 1.1rem;
}

.cookbook-header {
	line-height: 1;
}

.cookbook-header-logo {
	font-size: 2.25rem;
}